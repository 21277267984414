import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import PageBanner from '../../components/Common/PageBanner'
import Footer from "../../components/App/Footer"
import details1 from '../../assets/images/projects/wip.png'
import project2 from '../../assets/images/projects/project2.jpg'

const CaseDetails = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="Soleterre" 
                homePageText="Social balance and Monitoring and Evaluation" 
                homePageUrl="/social-balance-monitoring-evaluation" 
                activePageText="Soleterre" 
            />
            <CaseStudiesDetailsContent />
            <Footer />
        </Layout>
    );
}
export default CaseDetails

const CaseStudiesSidebar = () => {
    return (
        <div className="case-studies-sidebar-sticky">
            <div className="case-studies-details-info">
                <ul>
                    <li>
                        <div className="icon">
                            <i className='bx bx-user-pin'></i>
                        </div>
                        <span>Partner:</span>
                        <a href="https://www.soleterre.org/" target="_blank" rel="noreferrer">Sleterre</a>
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-map'></i>
                        </div>
                        <span>Location:</span>
	    		Italy
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-purchase-tag'></i>
                        </div>
                        <span>Technology:</span>
                       Dino 
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-money'></i>
                        </div>
                        <span>Donor:</span>
	    		Private funds
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-time'></i>
                        </div>
                        <span>Duration:</span>
                        1 year
                    </li>
                    <li>
                        <div className="icon">
                            <i className='bx bx-check'></i>
                        </div>
                        <span>Status</span>
                            On going
                    </li>
                </ul>
            </div>
        </div>
    )
}

const CaseStudiesDetailsContent = () => {
    return (
        <section className="case-studies-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="case-studies-details-image">
                            <img src={details1} alt="about" />
                        </div>
                        <div className="case-studies-details-desc">
                            <span className="sub-title">Digital Transformation</span>
                            <h3>The project</h3>
                            <p>
	    			Soleterre has created an internal monitoring and evaluation system to measure its strategies, which more recently have been structured through an internal ToC. However, the data collection system to effectively measure the achievement of outcomes is still based on a lot of manual work that makes the overall process cumbersome and prone to errors.<br/>
Gnucoop has therefore created a data collection and analysis platform based on DINO software, which allows the creation of defined forms based on existing data structures. Such data can be easily collected via webapp or mobile devices.<br/>
The system was then expanded to other areas of focus for the Foundation


			    </p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <CaseStudiesSidebar />
                    </div>
                </div>
	                </div>
        </section>
    )
}

